import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { CollapseIcon24, ExpandIcon24 } from "../../icons";
import Button from "../Button/Button";

const ListSection = (props) => {
  const { children, headline } = props;

  const numberOfArticle = 8;

  const [showArticle, setShowArticle] = useState(numberOfArticle);

  let colProps = "col-span-6";

  if (children.length === 1) {
    colProps = "col-span-12";
  }

  return (
    <div>
      <div className="grid grid-cols-12 gap-x-60 gap-y-42">
        <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">{headline}</div>
        {React.Children.map(children, (child, index) => (
          index < showArticle && <div className={classNames("even:mt-42", colProps)}>{child}</div>
        ))}
        {children.length > numberOfArticle && (
          <div className="col-start-5 col-span-3 mt-42">
            <Button
              onClick={() => {
                if (children.length >= showArticle) {
                  setShowArticle((showArticle + numberOfArticle));
                } else {
                  setShowArticle(numberOfArticle);
                }
              }}
              title={children.length > showArticle ? "Mehr laden" : "Weniger anzeigen"}
              variant="secondary"
            >
              {children.length > showArticle
                ? <><ExpandIcon24 /> Mehr Laden</>
                : <><CollapseIcon24 /> Weniger anzeigen</>}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

ListSection.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default ListSection;
