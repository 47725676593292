import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import DownloadCard from "../../components/DownloadCard/DownloadCard";
import DownloadGrid from "../../components/DownloadGrid/DownloadGrid";
import Eyecatcher from "../../components/Eyecatcher/Eyecatcher";
import ListSection from "../../components/ListSection/ListSection";
import Masonry from "../../components/Masonry/Masonry";
import MediaCard from "../../components/MediaCard/MediaCard";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import QuickFactsLink from "../../components/QuickFactsLink/QuickFactsLink";
import Sharing from "../../components/Sharing/Sharing";
import VideoPreview from "../../components/VideoPreview/VideoPreview";
import useMediaQuery from "../../hooks/useMediaQuery";
import { DownloadIcon24, ExternalLinkIcon24, FilePdfIcon36 } from "../../icons";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import DigitalArchiveHeader from "../layouts/DigitalArchiveHeader";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";
import GetIconComponents from "../utils/GetIconComponents";

const DigitalArchivePage = ({ data: { page } }) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation();

  return (
    <MainLayout
      breadcrumb={<Breadcrumb id={page.id} variant="secondary" />}
      header={<DigitalArchiveHeader />}
      variant="secondary"
    >
      <DetailPageHeader
        headline={page.title.trim() + (page.year ? `, ${page.year}` : "")}
        image={page.images?.images?.length > 0 && page.images?.images[0]?.asset && (
          <SanityImage
            {...page.images.images[0]}
            width={800}
            className="w-full"
          />

        )}
        sharing={(
          <Sharing
            shareTitle={page.title}
            title={t("shareItem")}
          />
        )}
        type={page.tag}
      />
      <div className="grid grid-cols-12 gap-x-12 m-18">
        {/* Content */}
        <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
          <div>
            {page._rawDescription && (
              <div className="grid grid-cols-12 lg:grid-cols-9">
                <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                  {t("description")}
                </div>
                <div className="col-span-12 lg:col-span-9 mt-24 lg:mt-42">
                  {page._rawDescription && (
                    <BlockContent blocks={page._rawDescription} />
                  )}
                </div>
              </div>
            )}
            {page.eyecatcher
              && (
                <div className="mt-90">
                  <Eyecatcher
                    headline={page.eyecatcher?.headline}
                    button={
                      page.eyecatcher?.callToAction
                      && (
                        <Button
                          color="white"
                          title="Button"
                          href={GetCallToActionContext(page.eyecatcher).path}
                        >{page.eyecatcher?.callToAction?.title}
                        </Button>
                      )
                    }
                    description={<BlockContent blocks={page.eyecatcher?._rawText} />}
                    logo={
                      React.createElement(
                        GetIconComponents().components[page.eyecatcher?.Icon?.component],
                      )
                    }
                  />
                </div>
              )}
            {page.images?.length > 0 && (
              <div className="grid grid-cols-12 lg:grid-cols-9 mt-40">
                <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                  {t("images")}
                </div>
                <div className="col-span-12 lg:col-span-9 mt-24 lg:mt-42">
                  <Masonry breakpointCols={isMobile ? 1 : 2}>
                    {page.images?.images
                      .map(
                        (image) => (
                          image.asset && (
                            <SanityImage
                              {...image}
                              width={800}
                              className="w-full mb-40"
                            />
                          )
                        ),
                      )}
                  </Masonry>
                </div>
              </div>
            )}
            {page.videos?.length > 0 && (
              <DownloadGrid title={t("videos")}>
                {page.videos?.map((video) => (
                  <MediaCard>
                    <VideoPreview
                      previewImageUrl={video.thumbnail}
                      videoUrl={video.url}
                    />
                  </MediaCard>
                ))}
              </DownloadGrid>
            )}
          </div>
          {page.scans?.length > 0 && (
            <DownloadGrid title={t("scans")}>
              {page.scans.map(
                (element) => (
                  <DownloadCard
                    button={<Button variant="secondary" color="white" title={element.asset?.originalFilename} href={element.asset?.url}><DownloadIcon24 />Download</Button>}
                    icon={<FilePdfIcon36 />}
                    title={element.asset?.originalFilename}
                    key={element.asset?.originalFilename}
                    cardBgColor="bg-grey"
                  >
                    {element.asset?.url}
                  </DownloadCard>
                ),
              )}
            </DownloadGrid>
          )}
        </div>
        {/* Quick Facts */}
        <div className="col-span-12 order-1 lg:order-2 lg:col-span-3 mb-40">
          <QuickFacts>
            {page.targetAudience && (
              <QuickFactsItem title={t("tagretGroup")}>
                {page.targetAudience}
              </QuickFactsItem>
            )}
            {page.furtherLinks.length > 0 && (
              <QuickFactsItem title={t("furtherLinks")}>
                {page.furtherLinks?.map((link) => (
                  <QuickFactsLink data={link} color="white" />
                ))}
              </QuickFactsItem>
            )}
          </QuickFacts>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityDigitalArchiveEntry(id: {eq: $id}) {
      id
      title
      tag
      time
      scans {
        asset {
          url
          originalFilename
        }
      }
      furtherLinks {
        title
        linkType
        href
        _rawInternalLink
        file {
          asset {
            assetId
            path
            url
          }
        }
      }
      slug {
        current
      }
      videos {
        url
        thumbnail
        service
        id
      }
      images {
        images {
          ...ImageWithPreview
          alt
        }
      }
      _rawDescription
      Metadata {
        ...MetaData
      }
    }
  }
`;

DigitalArchivePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default DigitalArchivePage;
