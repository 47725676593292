import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const DownloadCard = ({
  cardBgColor,
  icon,
  title,
  button,
}) => (
  <div className={classNames(cardBgColor, "bg-black p-24 lg:py-42 lg:px-36 text-white")}>
    <div className="mb-18">{icon}</div>
    <div className="text-lead mb-24 break-words">{title}</div>
    {button}
  </div>
);

DownloadCard.propTypes = {
  button: PropTypes.node.isRequired,
  cardBgColor: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

DownloadCard.defaultProps = {
  cardBgColor: null,
};

export default DownloadCard;
